
import Slider from 'react-slick';
import ReviewCard from '../ReviewCard/ReviewCard';
import './Work.css'
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import { useRef, useState } from 'react';

const Work = () => {

    const reviews = [
        {
            video_path: require("../../img/reviews/1.mp4"),
            poster: require("../../img/reviews/poster1.png")
        },
        {
            video_path: require("../../img/reviews/2.mp4"),
            poster: require("../../img/reviews/poster2.png")
        },
        {
            video_path: require("../../img/reviews/3.mp4"),
            poster: require("../../img/reviews/poster3.png")
        },
        {
            video_path: require("../../img/reviews/4.mp4"),
            poster: require("../../img/reviews/poster4.png")
        },
        {
            video_path: require("../../img/reviews/5.jpg"),
            poster: ""
        },
        {
            video_path: require("../../img/reviews/6.jpg"),
            poster: ""
        },
    ];

    const [slideIndex, setSlideIndex] = useState(0);
    const [updateCount, setUpdateCount] = useState(0);

    let sliderRef = useRef(null);
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ],
        afterChange: () => setUpdateCount(updateCount + 1),
        beforeChange: (current, next) => setSlideIndex(next)
    };

    const clickButton1 = () => {
        window.location.href = "https://t.me/deniscases";
    };

    const clickButton2 = () => {
        window.location.href = "https://t.me/denworks";
    };

    return (
        <div className="work-container" id="work">
            <div className='work-title'>
                <p>Работаем поэтапно</p>
            </div>
            <div className='work-info'>
                <div className='stage-cards'>
                    <div className="stage-card">
                        <div className='left-block'>
                            <div className='circle'>01</div>
                        </div>
                        <div className='right-block'>
                            <p>Получаем от Вас ТЗ или составляем его вместе</p>
                        </div>
                    </div>
                    <div className="stage-card">
                        <div className='left-block'>
                            <div className='circle'>02</div>
                        </div>
                        <div className='right-block'>
                            <p>Создаем дизайн и прототип всего приложения</p>
                        </div>
                    </div>
                    <div className="stage-card">
                        <div className='left-block'>
                            <div className='circle'>03</div>
                        </div>
                        <div className='right-block'>
                            <p>Верстаем WebApp и подключаем весь функционал</p>
                        </div>
                    </div>
                    <div className="stage-card">
                        <div className='left-block'>
                            <div className='circle'>04</div>
                        </div>
                        <div className='right-block'>
                            <p>Даю рекомендации по маркетингу проекта и делюсь опытом прошлых запусков</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='reviews'>
                <div className='reviews-title'>
                    <p>Отзывы по работе со мной</p>
                </div>

                <div className='left-arrow' onClick={e => sliderRef.slickGoTo(slideIndex-1)}>
                    <AiFillCaretLeft />
                </div>
                <div className='right-arrow' onClick={e => sliderRef.slickGoTo(slideIndex+1)}>
                    <AiFillCaretRight />
                </div>

                <div className='reviews-block'>
                    <Slider ref={slider => {
                        sliderRef = slider;
                        }} {...settings}>
                        {reviews.map((image, index) => (
                            <ReviewCard key={index} video_path={image.video_path} poster={image.poster} />
                        ))}
                    </Slider>
                </div>
            </div>
            <div className='contacts'>
                <div className='contact-card'>
                    <div className='image'>
                        <img src={require("../../img/case.png")} alt="case" width="56.47px" height="56.47px" />
                    </div>
                    <div className='contact-info' onClick={clickButton1}>
                        <p>Телеграм канал с кейсами</p>
                        <span>@deniscases</span>
                    </div>
                </div>
                <div className='contact-card'>
                    <div className='image'>
                        <img src={require("../../img/message.png")} alt="message" width="56.47px" height="56.47px" />
                    </div>
                    <div className='contact-info' onClick={clickButton2}>
                        <p>Консультация по вашему проекту</p>
                        <span>@denworks</span>
                    </div>
                </div>
                <div className='mobile-button' onClick={clickButton2}>
                    <div className='button-text'>ОБСУДИТЬ ПРОЕКТ</div>
                </div>
            </div>
        </div>
    );
};

export default Work;