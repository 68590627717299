import React from 'react';

const PartnerCard = ({ image }) => {
    return (
        <div className="partner-card">
            <div className='image'>
                <img src={image} alt="partner" />
            </div>
        </div>
    );
};

export default PartnerCard;