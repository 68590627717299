import './About.css'
import PartnerCard from '../PartnerCard/PartnerCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import { useRef, useState } from 'react';

const About = () => {

    let sliderRef = useRef(null);

    const partnerImages = [
        require('../../img/partners/ayaz.jpg'),
        require('../../img/partners/timochko.jpg'),
        require('../../img/partners/romanovich.jpg'),
        require('../../img/partners/batyrev.jpg'),
        require('../../img/partners/banana.jpg'),
        require('../../img/partners/maxchir.jpg'),
        require('../../img/partners/rizvanova.jpg'),
    ];

    const [slideIndex, setSlideIndex] = useState(0);
    const [updateCount, setUpdateCount] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        swipeToSlide: true,
        afterChange: () => setUpdateCount(updateCount + 1),
        beforeChange: (current, next) => setSlideIndex(next)
    };

    

    const clickButton = () => {
        window.location.href = "https://t.me/denworks";
    };

    return (
        <div className='about-container' id="about">

            <div className='mobile-about-card'>
                <div className='info-incard'>
                    <div className='button-side'>
                        <div className='text-container'>
                            <p>Нужна срочная разработка WebApp?</p>
                            <div className="with-image">
                                <img src={require("../../img/contact.png")} alt="contact" width="40.28px" height="40.28px" draggable="false" />
                                <p>Нажмите на кнопку ниже и расскажите подробнее о вашем проекте</p>
                                <svg width="38" height="52" viewBox="0 0 38 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30.9697 51.5303C31.2626 51.8232 31.7374 51.8232 32.0303 51.5303L36.8033 46.7574C37.0962 46.4645 37.0962 45.9896 36.8033 45.6967C36.5104 45.4038 36.0355 45.4038 35.7426 45.6967L31.5 49.9393L27.2574 45.6967C26.9645 45.4038 26.4896 45.4038 26.1967 45.6967C25.9038 45.9896 25.9038 46.4645 26.1967 46.7574L30.9697 51.5303ZM0 1.75C8.74139 1.75 14.9745 3.99425 19.4347 7.50349C23.9008 11.0175 26.6599 15.8556 28.3478 21.1647C31.7394 31.833 30.75 44.1588 30.75 51H32.25C32.25 44.3412 33.2606 31.667 29.7772 20.7103C28.0276 15.2069 25.1304 10.0763 20.3622 6.32464C15.588 2.56825 9.00861 0.25 0 0.25V1.75Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                        <div className='button' onClick={clickButton}>
                            <div className='button-text'>ОБСУДИТЬ ПРОЕКТ</div>
                        </div>
                    </div>

                    <div className="image-side">
                        <img src={require("../../img/denis_2.png")} alt="denis_2" width="695px" height="600px" draggable="false" />
                        <div className="about-overlay">
                            <p>Денис Кошкаров</p>
                            <span>Маркетолог. Разработчик. Основатель студии разработки WebApp внутри Telegram.</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='about-card'>
                <div className='info-incard'>
                    <div className="image-side">
                        <img src={require("../../img/denis_2.png")} alt="denis_2" width="695px" height="600px" draggable="false" />
                        <div className="about-overlay">
                            <p>Денис Кошкаров</p>
                            <span>Маркетолог. Разработчик. Основатель студии разработки WebApp внутри Telegram.</span>
                        </div>
                    </div>
                    <div className='button-side'>
                        <div className='text-container'>
                            <p>Нужна срочная разработка WebApp?</p>
                            <div className="with-image">
                                <img src={require("../../img/contact.png")} alt="contact" width="40.28px" height="40.28px" draggable="false" />
                                <p>Нажмите на кнопку ниже и расскажите подробнее о вашем проекте</p>
                                <svg width="38" height="52" viewBox="0 0 38 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30.9697 51.5303C31.2626 51.8232 31.7374 51.8232 32.0303 51.5303L36.8033 46.7574C37.0962 46.4645 37.0962 45.9896 36.8033 45.6967C36.5104 45.4038 36.0355 45.4038 35.7426 45.6967L31.5 49.9393L27.2574 45.6967C26.9645 45.4038 26.4896 45.4038 26.1967 45.6967C25.9038 45.9896 25.9038 46.4645 26.1967 46.7574L30.9697 51.5303ZM0 1.75C8.74139 1.75 14.9745 3.99425 19.4347 7.50349C23.9008 11.0175 26.6599 15.8556 28.3478 21.1647C31.7394 31.833 30.75 44.1588 30.75 51H32.25C32.25 44.3412 33.2606 31.667 29.7772 20.7103C28.0276 15.2069 25.1304 10.0763 20.3622 6.32464C15.588 2.56825 9.00861 0.25 0 0.25V1.75Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                        <div className='button' onClick={clickButton}>
                            <div className='button-text'>ОБСУДИТЬ ПРОЕКТ</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='about-partners'>
                <div className='left-block'>
                    <p>За 4 года поработал с топами рынка</p>
                    <span>Аяз, Тимочко, Романович, Батырев и др.</span>
                </div>
                <div className='left-arrow' onClick={e => sliderRef.slickGoTo(slideIndex-1)}>
                        <AiFillCaretLeft />
                    </div>
                    <div className='right-arrow' onClick={e => sliderRef.slickGoTo(slideIndex+1)}>
                        <AiFillCaretRight />
                    </div>
                <div className='right-block'>
                    
                    <Slider ref={slider => {
                        sliderRef = slider;
                        }} {...settings}>
                        {partnerImages.map((image, index) => (
                            <PartnerCard key={index} image={image} />
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );

};

export default About;