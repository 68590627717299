import { useRef } from "react";
import "./ProjectCard.css"

const ProjectCard = ({img_path, video_path, title, desc, poster}) => {

    const videoRef = useRef(null);

    const handleVideoClick = () => {
        const video = videoRef.current;
        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
    };

    return (
        <div className="project-card">
            <div className="top-content">
                <img src={img_path} alt="webapp" width="320.96px" height="630px" draggable={false} />
                <video src={video_path} ref={videoRef} poster={poster} width="320.96px" height="630px" draggable={false} muted playsInline preload="metadata" onClick={handleVideoClick} />
            </div>
            <div className="bottom-content">
                <div className="card-text">
                    <p>{title}</p>
                    <span>{desc}</span>
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;