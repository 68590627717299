import React from 'react'
import Navbar from "../Navbar/Navbar"
import "./Home.css";

const Home = () => {
    return (
        <div className='homeContainer fadein' id="home">
            <Navbar />
            <div className="home-background">
                <img src={require("../../img/homebackground.png")} alt="background" />
            </div>
            <div className='home-top'>
                <div className='home-top-text'>
                    <p>Разработаем WebApp</p>
                    <p>в <svg width="78" height="64" viewBox="0 0 78 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.15334 27.3744C26.6068 18.4917 40.2229 12.5895 47.0602 9.72598C66.5201 1.60305 70.6108 0.200528 73.2405 0.14209C73.8249 0.14209 75.1106 0.258967 75.9871 0.960227C76.6884 1.54461 76.8637 2.30431 76.9806 2.88869C77.0975 3.47308 77.2143 4.70028 77.0975 5.63529C76.0456 16.7386 71.4874 43.6786 69.1499 56.0676C68.1564 61.327 66.2279 63.0802 64.3579 63.2555C60.2672 63.6061 57.17 60.5673 53.2546 57.996C47.0602 53.9638 43.6123 51.4509 37.5932 47.4771C30.639 42.9189 35.1388 40.4061 39.1126 36.3154C40.1644 35.2635 58.105 18.9008 58.4556 17.4398C58.5141 17.2645 58.5141 16.5633 58.105 16.2126C57.6959 15.862 57.1116 15.9789 56.6441 16.0958C56.0012 16.2126 46.1836 22.7577 27.0743 35.6726C24.2692 37.6011 21.7564 38.5361 19.4773 38.4776C16.9644 38.4192 12.1725 37.0751 8.54931 35.9063C4.16644 34.5038 0.660138 33.7441 0.95233 31.2897C1.12764 30.0041 2.88079 28.7184 6.15334 27.3744Z" fill="#3187FF"/>
                        </svg> <span>Telegram</span> за 7 дней</p>
                </div>
                <div className='home-middle-text'>
                    <p>Реализовали более 20 приложений внутри Telegram. От «тапалок» для crypto проектов до интернет-магазинов с играми</p>
                </div>
            </div>
            <div className='home-bottom'>
                <div className='home-bottom-image'>
                    <img src={require("../../img/denis.png")} alt="denis" width="620px" height="621px" />
                </div>
                <div className='home-bottom-container'>

                    <div className='mobile-cardRight'>
                        <div className='right-card-imgblock'>
                            <div className='image'><img src={require("../../img/avatar.png")} alt="project" width="84.81px" height="84.81px" /></div>
                        </div>
                        <div className='right-card-textblock'>
                            <p>Денис Кошкаров</p>
                            <span>Создаю воронки продаж. Внедряю реферальные решения. Разрабатываю IT сервисы и WebApp приложения внутри Telegram.</span>
                        </div>
                    </div>

                    <div className='mobile-cardLeft'>
                        <div className='left-card-textblock'>
                                <div className="top-text">
                                    <p>20+</p>
                                    <span>WebApp разработано с начала 2024 года: От различных «тапалок» по типу Hamster Combat до полноценных интернет-магазинов и игр.</span>
                                </div>
                                <div className='bottom-btn'>
                                    <div className='btn-text' onClick={() => {
                                        window.location.href = "/#projects"
                                    }}>Смотреть работы</div>
                                </div>
                            </div>
                            <div className='left-card-imgblock'>
                                <div className="first-line">
                                    <div className='image'><img src={require("../../img/projects/1.png")} alt="project" width="90.54px" height="150px" /></div>
                                    <div className='image'><img src={require("../../img/projects/2.png")} alt="project" width="90.54px" height="150px" /></div>
                                    <div className='image'><img src={require("../../img/projects/3.png")} alt="project" width="90.54px" height="150px" /></div>
                                </div>
                                <div className="second-line">
                                    <div className='image'><img src={require("../../img/projects/4.png")} alt="project" width="90.54px" height="150px" /></div>
                                    <div className='image'><img src={require("../../img/projects/5.png")} alt="project" width="90.54px" height="150px" /></div>
                                    <div className='image'><img src={require("../../img/projects/6.png")} alt="project" width="90.54px" height="150px" /></div>
                                </div>
                            </div>
                    </div>

                    <div className='left-card'>
                        <div className='left-card-textblock'>
                            <div className="top-text">
                                <p>20+</p>
                                <span>WebApp разработано с начала 2024 года: От различных «тапалок» по типу Hamster Combat до полноценных интернет-магазинов и игр.</span>
                            </div>
                            <div className='bottom-btn'>
                                <div className='btn-text' onClick={() => {
                                    window.location.href = "/#projects"
                                }}>Смотреть работы</div>
                            </div>
                        </div>
                        <div className='left-card-imgblock'>
                            <div className="first-line">
                                <div className='image'><img src={require("../../img/projects/1.png")} alt="project" width="90.54px" height="150px" /></div>
                                <div className='image'><img src={require("../../img/projects/2.png")} alt="project" width="90.54px" height="150px" /></div>
                                <div className='image'><img src={require("../../img/projects/3.png")} alt="project" width="90.54px" height="150px" /></div>
                            </div>
                            <div className="second-line">
                                <div className='image'><img src={require("../../img/projects/4.png")} alt="project" width="90.54px" height="150px" /></div>
                                <div className='image'><img src={require("../../img/projects/5.png")} alt="project" width="90.54px" height="150px" /></div>
                                <div className='image'><img src={require("../../img/projects/6.png")} alt="project" width="90.54px" height="150px" /></div>
                            </div>
                        </div>
                    </div>
                    <div className='right-card'>
                        <div className='right-card-imgblock'>
                            <div className='image'><img src={require("../../img/avatar.png")} alt="project" width="84.81px" height="84.81px" /></div>
                        </div>
                        <div className='right-card-textblock'>
                            <p>Денис Кошкаров</p>
                            <span>Создаю воронки продаж. Внедряю реферальные решения. Разрабатываю IT сервисы и WebApp приложения внутри Telegram.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
