import './App.css';
import About from './components/About/About';
import Home from './components/Home/Home';
import Projects from './components/Projects/Projects';
import Work from './components/Work/Work';
import { VideoProvider } from './components/VideoContext/VideoContext';
import useImagePreloader from './hooks/Preloader';

import Image1 from './img/avatar.png';
import Image2 from './img/case.png';
import Image3 from './img/contact.png';
import Image4 from './img/denis_2.png';
import Image5 from './img/denis.png';
import Image6 from './img/homebackground.png';
import Image7 from './img/message.png';
import Image8 from './img/webapp/1.jpg';
import Image9 from './img/webapp/2.jpg';
import Image10 from './img/webapp/3.jpg';
import Image11 from './img/reviews/5.jpg';
import Image12 from './img/reviews/6.jpg';
import Image13 from './img/projects/1.png';
import Image14 from './img/projects/2.png';
import Image15 from './img/projects/3.png';
import Image16 from './img/projects/4.png';
import Image17 from './img/projects/5.png';
import Image18 from './img/projects/6.png';
import Image19 from './img/partners/ayaz.jpg';
import Image20 from './img/partners/banana.jpg';
import Image21 from './img/partners/batyrev.jpg';
import Image22 from './img/partners/maxchir.jpg';
import Image23 from './img/partners/rizvanova.jpg';
import Image24 from './img/partners/romanovich.jpg';
import Image25 from './img/partners/timochko.jpg';
import gif from "./img/25.gif";

const preloadSrcList = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
    Image12,
    Image13,
    Image14,
    Image15,
    Image16,
    Image17,
    Image18,
    Image19,
    Image20,
    Image21,
    Image22,
    Image23,
    Image24,
    Image25,
];

function App() {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList)

  return (
    <div className='App'>
      {imagesPreloaded ? (
        <div>
          <Home />
          <Projects />
          <About />
          <VideoProvider>
          <Work />
          </VideoProvider>
        </div>
      ) : (
        <div className='loading'>
          <p>Загрузка</p>
          <img src={gif} alt="load" />
        </div>
      )}
    
    </div>
    
  );
}

export default App;
